<template>
  <div>
    <a-menu :selectedKeys="[$route.name]" mode="inline" theme="dark" @click="onRouteSelected" :inline-collapsed="false">
      <a-menu-item key="ModelProductList">
        <span>产品列表</span>
      </a-menu-item>
      <a-menu-item key="ModelLoadDeviceList">
        <span>负载类型列表</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onRouteSelected(route) {
      this.$router.push({ name: route.key });
    },
  },
};
</script>
