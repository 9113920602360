<template>
  <div class="shadow">
    <header class="a-header header-layout">
      <div class="header-title">
        <h1 class="title">RCS平台物模型</h1>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'ToolBar',
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.shadow {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
p {
  width: 100px;
  text-align: center;
}
.header-layout {
  display: flex;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  background: white;
  color: white;
  .header-right-layout {
    margin-right: 4%;
    display: flex;
    align-items: center;
    .setting-layout {
      margin-right: 0px;
    }
    .function-icon-layout {
      margin: 0 15px;
      display: flex;
      align-items: flex-end;
      .selector {
        cursor: pointer;
      }
      .icon {
        width: 25px;
        margin-right: 3px;
      }
    }
  }
  .role-layout {
    margin-right: 20px;
    font-size: 14px;
  }
}
.header-title {
  margin-left: 40px;
  align-self: center;
  font-size: 1rem;
  .title {
    color: black;
  }
}

.user-info-layout {
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 0.8rem;
  margin-right: 1rem;
}
.personal-ctrl {
  p {
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
.user-info-text {
  align-self: center;
}
</style>
